<template>
  <section class="common-section features-section full__width--section">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="intro-container">
            <div class="heading">
              {{ $t("landing_page.features_section.title") }}
            </div>
            <div class="content">
              {{ $t("landing_page.features_section.content") }}
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 features-container">
          <VueSlickCarousel
            v-bind="sliderOptions"
            class="slick__slider--container white-dots variant3 features-slider"
          >
            <div
              class="spadding-container"
              v-for="item in featuresList"
              :key="item.id"
            >
              <div class="features-inner">
                <div class="item-image">
                  <img :src="item.image" :alt="item.title" />
                </div>
                <div class="item-title">
                  {{ item.title }}
                </div>
                <div class="item-content">
                  {{ item.content }}
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      featuresListImages: [
        {
          image: require("@/assets/images/landing/features/recommendations.svg")
        },
        {
          image: require("@/assets/images/landing/features/collaborative-spaces.svg")
        },
        {
          image: require("@/assets/images/landing/features/classroom.svg")
        },
        {
          image: require("@/assets/images/landing/features/awards.svg")
        },
        {
          image: require("@/assets/images/landing/features/interactive-content.svg")
        },
        {
          image: require("@/assets/images/landing/features/suggestions.svg")
        }
      ],
      sliderOptions: {
        slidesToShow: 5,
        slidesToScroll: 1,
        draggable: false,
        arrows: false,
        infinite: false,
        autoplay: false,
        responsive: [
          {
            breakpoint: 9999,
            settings: "unslick"
          },
          {
            breakpoint: 1199,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              variableWidth: true,
              centerMode: true
            }
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
              variableWidth: true,
              centerMode: true
            }
          }
        ]
      }
    };
  },
  computed: {
    featuresList() {
      return this.featuresListImages.map((feature, index) => ({
        ...feature,
        title: this.$t(
          `landing_page.features_section.feature${index + 1}.title`
        ),
        content: this.$t(
          `landing_page.features_section.feature${index + 1}.content`
        )
      }));
    }
  }
};
</script>

<style lang="scss" scoped>
.features-section {
  font-family: $font-family;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  background: radial-gradient(
    84.47% 80.41% at 33.55% 63.04%,
    $brand-secondary1-400 0%,
    $brand-primary-400 100%
  );
  .intro-container {
    .heading {
      @include title-large;
      color: $brand-primary-50;
    }
    .content {
      margin-top: 8px;
      @include h6;
      color: $brand-primary-50;
    }
  }
  .features-container {
    margin-top: 48px;
  }
  .features-inner {
    width: 100%;
    @include flex-column-start;
    text-align: left;
    padding: 0 16px;
    .item-title {
      margin-top: 16px;
      @include h6(500);
      color: $brand-neutral-0;
      word-break: break-word;
    }
    .item-content {
      margin-top: 4px;
      height: 108px;
      @include subtitle-regular;
      font-weight: 400;
      color: $brand-neutral-0;
      word-break: break-word;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .features-slider {
    display: flex;
    flex-wrap: wrap;
    .spadding-container {
      width: 30.66%;
      &:not(:nth-child(-n + 3)) {
        margin-top: 96px;
      }
      &:not(:nth-child(3n + 1)) {
        margin-left: 4%;
      }
    }
  }
}

@media only screen and (max-width: 1199px) {
  .features-slider {
    padding-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .full__width--section {
    .container {
      max-width: 100%;
      padding-left: 28px;
      padding-right: 28px;
      .row {
        margin-left: -28px;
        margin-right: -28px;
        .col-12 {
          padding-left: 28px;
          padding-right: 28px;
        }
      }
    }
  }
  .features-section {
    .intro-container {
      .heading {
        @include h3;
        color: $brand-primary-50;
        max-width: calc(100% - 200px);
        margin-left: auto;
        margin-right: auto;
      }
    }
    .features-inner {
      .item-title {
        height: 58px;
        word-break: break-word;
      }
      .item-content {
        height: 135px;
      }
    }
    .features-slider {
      :deep .slick-slide {
        width: 266px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .features-section {
    .intro-container {
      .heading {
        max-width: calc(100% - 16px);
      }
    }
  }
}

[dir="rtl"] {
  @media only screen and (min-width: 1200px) {
    .features-slider {
      .spadding-container {
        &:not(:nth-child(3n + 1)) {
          margin-left: 0;
          margin-right: 4%;
        }
      }
    }
  }
}
</style>
