<template>
  <Banner
    :bannerTitle="bannerTitle"
    :bannerHighlightTitle="bannerHighlightTitle"
    :bannerContent="bannerContent"
    :bannerBtnText="bannerBtnText"
    :bannerDesktopImage="bannerDesktopImage"
    :bannerBtn="true"
    :comingSoonBtn="true"
    :isVariant2="true"
    :isTopBanner="false"
  />
</template>

<script>
import Banner from "./Banner.vue";
export default {
  components: { Banner },
  data() {
    return {
      bannerDesktopImage: require("@/assets/images/landing/banner/learn-more-banner.png")
    };
  },
  computed: {
    bannerTitle() {
      return this.$t("landing_page.learn_more_section.title");
    },
    bannerHighlightTitle() {
      return this.$t("landing_page.learn_more_section.title_0");
    },
    bannerContent() {
      return this.$t("landing_page.learn_more_section.content");
    },
    bannerBtnText() {
      return this.$t("partner.button.learn_more");
    }
  }
};
</script>
